import { TenantStage } from './tenant';
import { TokenServices } from './token';

export interface AccountManagementGroup {
  uuid: string;
  name: string;
  owner: string;
  description: string;
  hidden: boolean;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface AccountManagementGroupList {
  count: number;
  items: AccountManagementGroup[];
}

export interface AccountManagementPolicy {
  uuid: string;
  name: string;
  description?: string;
  tags?: string[];
  statementQuery?: string;
}

export interface AccountManagementAccountResource {
  tokenService: TokenServices;
  stage: TenantStage;
  instructorGroupUuid: string;
  participantPolicyUuid: string;
}

export const AccountManagementAdminIds: number[] = [
  1039718, 7364056, 7305701, 6057183, 7322348, 15384, 7344670, 7380272, 7328481, 2794326, 20318, 28432, 7317932, 21969, 6263, 12383, 14533,
  19271, 7344671, 4631, 7327881, 28499, 7314770, 7348671, 7380684, 7315452, 7327910, 7364714, 7328481, 48742, 7380272, 20336, 7383435,
  7445029, 47694, 16869, 7361890, 7327879, 2794326, 21966, 7309314, 7414239, 11636, 21667, 15384, 7365289, 7341475, 7339956, 7404154,
  7368274, 7364948, 19695, 7408035, 3073432, 7332922, 7310733, 19947, 7333511, 47529, 7354793, 7357128, 7352114, 7372453, 6835996, 4064186,
];

export const AccountManagementAccountUuidToResource: { [accountUuid: string]: AccountManagementAccountResource } = {
  // DTU
  '65458478-ab94-4669-b798-108436f8d65e': {
    tokenService: TokenServices.AccountManagementAutomationProd,
    stage: TenantStage.Production,
    instructorGroupUuid: 'd8df2661-1bf8-427d-ba2f-013f1ea7a934',
    participantPolicyUuid: '6eaa9fdb-1219-4ce7-b512-d0aafa2baf92',
  },
  'f02d2eb1-d9c3-4aa4-811f-87bf0f617f73': {
    tokenService: TokenServices.AccountManagementAutomationStg,
    stage: TenantStage.Hardening,
    instructorGroupUuid: '4cd70b21-9e68-46c1-b14b-fa700c26202d',
    participantPolicyUuid: 'c63d3dee-761e-4ea4-a6a1-e5febe12a6c7',
  },

  // Perform
  '193e015b-dc2d-47b8-a80b-06db52612e8c': {
    tokenService: TokenServices.AccountManagementAutomationPerformS01Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '5d70c7eb-cf53-446a-98cc-0a0bee11bb94',
    participantPolicyUuid: '8a68f76a-3bc9-4768-b60e-e57710139056',
  },
  'd6f18973-1d34-4a54-9db0-c402f1d286cc': {
    tokenService: TokenServices.AccountManagementAutomationPerformS02Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '406cc305-0789-4135-9411-237b84eb899a',
    participantPolicyUuid: '17680be0-5411-499a-8fa1-ea29961c4d76',
  },
  '640f8cc5-9c11-4b57-a310-d5891ef59b58': {
    tokenService: TokenServices.AccountManagementAutomationPerformS03Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '5f8bfa76-2132-4238-8530-af45cedfc593',
    participantPolicyUuid: '5dd24620-8f7d-4050-911d-c28ddbf33484',
  },
  '15e69855-c24b-407f-802b-4bf53b323ea4': {
    tokenService: TokenServices.AccountManagementAutomationPerformS04Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '578a5070-14e7-4a58-ae77-3a1f525879ab',
    participantPolicyUuid: '8a80d1ed-5f58-4b48-9525-265971512ddc',
  },
  '52c5d78b-17f4-457d-b474-960db99fc641': {
    tokenService: TokenServices.AccountManagementAutomationPerformS05Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: 'e31fef37-3ba9-4f0c-b00a-669666b7c6c2',
    participantPolicyUuid: 'ed2d8842-5cc3-44aa-98bd-30908ff8370e',
  },
  '14d1228a-1427-4084-80dc-03c2865fbd58': {
    tokenService: TokenServices.AccountManagementAutomationPerformS06Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '491e6513-38b4-4885-a3d2-80f5a84bf3b1',
    participantPolicyUuid: '034b90f4-29e9-4be1-9e03-518f4850fe38',
  },
  '75d97665-1dc7-4633-8d97-b012ae5e1e72': {
    tokenService: TokenServices.AccountManagementAutomationPerformS07Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: 'cea9740d-1972-4bc8-a54a-e324a6620454',
    participantPolicyUuid: 'ecef57a2-45b1-42c6-952b-9637ba8ab6c8',
  },
  '48d81141-fbd2-4cdd-a931-aef585a4b8fb': {
    tokenService: TokenServices.AccountManagementAutomationPerformS08Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '1aa6b079-5ea2-46c5-aed3-7c9a2dd297b6',
    participantPolicyUuid: 'f62aac73-f32e-4057-b0c0-44098fbde9de',
  },
  '5cf32eea-787a-4fe7-a4f2-094745473013': {
    tokenService: TokenServices.AccountManagementAutomationPerformS09Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '7afb8fc8-9bd4-4916-93b3-c7b8b3a96fc6',
    participantPolicyUuid: '75d18b4b-aa12-4973-9123-88e8c197012b',
  },
  '9e1c0fc8-d97f-42c4-9c99-a8cfd4909d31': {
    tokenService: TokenServices.AccountManagementAutomationPerformS10Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '716a410a-3b4b-44ac-9e90-7a4ca733a238',
    participantPolicyUuid: '19c9159c-6377-419a-92b0-12a85998fd97',
  },
  '352255b6-3f7c-4217-84d2-99df150c85ef': {
    tokenService: TokenServices.AccountManagementAutomationPerformS11Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '52d3ce39-77fd-4f9f-a451-c154fa5086c4',
    participantPolicyUuid: '4af5fdb2-58be-42e3-bc30-564adbad3e9c',
  },
  '1b84e8f4-9867-4e24-90eb-97d06a3b879c': {
    tokenService: TokenServices.AccountManagementAutomationPerformS12Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '7878af97-9926-450b-ba09-858938f3f121',
    participantPolicyUuid: 'a213fef4-5cfa-4c78-a3ed-1854eac29b86',
  },
  'e512214f-25c5-456a-a53c-d3addde4ed09': {
    tokenService: TokenServices.AccountManagementAutomationPerformS13Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '58b01d11-3b32-4249-89f6-f3b51d713a9a',
    participantPolicyUuid: '45d79409-a2c9-4865-b02d-d754f312030e',
  },
  'a562b172-0b1a-447d-a025-3e216e9cd7a5': {
    tokenService: TokenServices.AccountManagementAutomationPerformS14Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: 'aa9d1d45-f67b-4ea5-a63c-a369808dd250',
    participantPolicyUuid: 'a59fd467-79c1-4bb5-a351-dba8b3d5eb8c',
  },
  '562d75aa-9e4c-405d-93cf-17c779826467': {
    tokenService: TokenServices.AccountManagementAutomationPerformS15Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '99e254fd-a2a9-4ae8-8d49-74152c183c05',
    participantPolicyUuid: '37c335b5-cfd1-4b24-a970-44f19191029e',
  },
};
