import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { RedirectGuard } from './shared/auth/redirect-guard.service';

/**
 * Routes for the root application
 */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { externalUrl: environment.authRedirect },
  },
  {
    path: 'overview',
    pathMatch: 'full',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { externalUrl: environment.marketingUrl },
  },
  {
    path: 'home',
    pathMatch: 'full',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { externalUrl: environment.marketingUrl },
  },
  { path: 'account/certificates', pathMatch: 'prefix', redirectTo: '/account/badges' },
  { path: 'account/environments', pathMatch: 'prefix', redirectTo: '/account/events' },
  { path: 'account/orders', pathMatch: 'prefix', redirectTo: '/account/events' },
  { path: 'certification/faq', pathMatch: 'prefix', redirectTo: '/faq' },
  { path: 'certification/acm', pathMatch: 'prefix', redirectTo: '/certification' },
  { path: 'certification/dynatrace', pathMatch: 'prefix', redirectTo: '/certification' },
  { path: 'certification/21181', pathMatch: 'prefix', redirectTo: '/certification' },
  { path: 'certification/21971', pathMatch: 'full', redirectTo: '/certification' },
  { path: 'certification/21971/exam/24685/schedule', pathMatch: 'full', redirectTo: '/certification' },
  { path: 'certification/21971/exam/24686/schedule', pathMatch: 'full', redirectTo: '/certification' },
  { path: 'ondemand/getting-started', pathMatch: 'prefix', redirectTo: '/ondemand' },
  { path: 'ondemand/dynatrace', pathMatch: 'prefix', redirectTo: '/ondemand' },
  {
    path: 'perform-certifications',
    pathMatch: 'full',
    loadChildren: () => import('./perform/kiosk/kiosk.module').then((m) => m.KioskModule),
  },
  { path: 'perform-leaderboard', pathMatch: 'full', redirectTo: '/error?status=404' },
  { path: 'playlists', pathMatch: 'prefix', redirectTo: '/ondemand?content=learning-paths' },
  { path: 'training', pathMatch: 'prefix', redirectTo: '/live-training' },
  { path: 'videos/:videoId', pathMatch: 'full', redirectTo: '/ondemand/video/:videoId' },

  { path: 'account', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule) },
  { path: 'certification', loadChildren: () => import('./certification/certification.module').then((m) => m.CertificationModule) },
  { path: 'error', loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule) },
  {
    path: 'exams/proctorapproval',
    loadChildren: () => import('./proctor-portal/proctor-portal.module').then((m) => m.ProctorPortalModule),
  },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then((m) => m.FAQModule) },
  { path: 'live-training', loadChildren: () => import('./training/training.module').then((m) => m.TrainingModule) },
  { path: 'maintenance', loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule) },
  { path: 'ondemand', loadChildren: () => import('./ondemand/ondemand.module').then((m) => m.OnDemandModule) },
  { path: 'survey', loadChildren: () => import('./survey/survey.module').then((m) => m.SurveyModule) },
  { path: '**', pathMatch: 'full', redirectTo: '/error?status=404' },
];

/**
 * Class representing the App Routing module
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
