import { Clipboard } from '@angular/cdk/clipboard';
import { HttpParams } from '@angular/common/http';
import { Injectable, RendererFactory2 } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ErrorResponse, User, UserSession } from '@dtu/types';
import { FORBIDDEN, NOT_FOUND, UNAUTHORIZED } from 'http-status-codes';
import { Subject } from 'rxjs';
import { AuthApiService } from './shared/api/auth.service';
import { XSRFService } from './shared/api/xsrf.service';

/**
 * Class representing the App service
 */
@Injectable()
export class AppService {
  // Initialize user account
  account: User;

  // Initialize dark mode and navigation
  darkMode = true;
  sidenavCollapsed = true;

  // Initialize fullscreen tracking
  fullscreenChange = new Subject<boolean>();
  fullscreenEvent = this.fullscreenChange.asObservable();

  // Initialize translation options
  translationOptions = [
    { language: 'Chinese [auto]', code: 'zh' },
    { language: 'French [auto]', code: 'fr' },
    { language: 'German [auto]', code: 'de' },
    { language: 'Italian [auto]', code: 'it' },
    { language: 'Japanese [auto]', code: 'ja' },
    { language: 'Korean [auto]', code: 'ko' },
    { language: 'Portuguese [auto]', code: 'pt' },
    { language: 'Russian [auto]', code: 'ru' },
    { language: 'Spanish [auto]', code: 'es' },
    { language: 'Thai [auto]', code: 'th' },
  ];
  languageOptionsTooltip = [
    'English',
    ...this.translationOptions.map((translation: { language: string; code: string }) => {
      return translation.language.replace(' [auto]', '');
    }),
  ].join(', ');

  /**
   * App service
   * @param clipboard - Material clipboard
   * @param metaService - Meta service
   * @param rendererFactory - Angular renderer
   * @param router - Angular router
   * @param snackBar - Material snackbar
   * @param titleService - Title service
   */
  constructor(
    private readonly authApiService: AuthApiService,
    private readonly clipboard: Clipboard,
    private readonly metaService: Meta,
    private readonly rendererFactory: RendererFactory2,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly titleService: Title,
    private readonly xsrfService: XSRFService,
  ) {}

  /**
   * Initialize application
   */
  initApp(): void {
    this.setDarkMode();
  }

  /**
   * Append query params to an existing HttpParams object
   * @param currentParams - An existing HttpParams object to add new params to
   * @param newParams - New params to add to query params
   * @param remappings - Record of properties to rename before adding param
   * @param keyExcludeList - List of properties names to exclude from query params
   * @param keyValueExcludeRecord - Record of key-value pairs to exclude from query params
   * @returns The HttpParams object
   */
  appendQueryParams(
    currentParams: HttpParams,
    newParams: Record<string, any>,
    remappings: Record<string, string> = null,
    keyExcludeList: string[] = [],
    keyValueExcludeRecord: Record<string, any[]> = {},
  ): HttpParams {
    const paramEntries = Object.entries(newParams);
    const keysToRemap = remappings ? Object.keys(remappings) : null;
    const keysToConditionallyRemove = Object.keys(keyValueExcludeRecord);

    for (const param of paramEntries) {
      const paramKey = param[0];
      const paramValue = param[1];

      if (
        paramValue === undefined ||
        paramValue === null ||
        paramValue === '' ||
        keyExcludeList?.includes(paramKey) ||
        (keysToConditionallyRemove?.includes(paramKey) && keyValueExcludeRecord[paramKey]?.includes(paramValue)) ||
        (Array.isArray(paramValue) && !paramValue.length)
      ) {
        continue;
      }

      let queryParamKey = paramKey;
      if (keysToRemap?.includes(paramKey)) {
        queryParamKey = remappings[paramKey];
      }

      let queryParamValue = paramValue;
      if (queryParamKey === 'startTime') {
        queryParamValue = new Date(paramValue).toISOString();
      } else if (queryParamKey === 'endTime') {
        queryParamValue = new Date(new Date(paramValue).setHours(23, 59, 59, 999)).toISOString();
      }

      currentParams = currentParams.set(queryParamKey, queryParamValue.toString());
    }

    return currentParams;
  }

  /**
   * Handle component error
   * @param error - Error response
   * @param customMessage - Custom message to show user
   */
  componentError(error: ErrorResponse, _customMessage: string): void {
    const statusWithErrorPage = [FORBIDDEN, NOT_FOUND];
    if (error.status === UNAUTHORIZED) {
      this.authApiService.signIn();
    } else if (statusWithErrorPage.includes(error.status)) {
      this.router.navigate(['/error'], { queryParams: { status: error.status }, replaceUrl: true });
    }
  }

  /**
   * Copy content
   * @param content - Text to copy to clipboard
   */
  copyContent(content: string): void {
    this.clipboard.copy(content);
    this.openSnackBar('Copied', 1000);
  }

  /**
   * Get active filter count
   * @param filters - List of filter keys
   * @param params - Object containing the filter values
   * @param multipleParamKeys - List of filter keys that correspond to multiple values (Current supports: ['dateRange'])
   * @returns The count of filters that have values set in the params object
   */
  getActiveFilterCount(filters: { key: string }[], params: Record<string, any>, multipleParamKeys: string[] = []): number {
    const supportedMultipleParamKeys = ['dateRange'];
    for (const key of multipleParamKeys) {
      if (!supportedMultipleParamKeys.includes(key)) {
        throw Error('Unsupported multiple param key');
      }
    }

    const reducer = (count: number, filter: { key: string }): number =>
      !supportedMultipleParamKeys.includes(filter.key) && params[filter.key] ? ++count : count;
    let activeFilterCount = filters.reduce(reducer, 0);

    for (const key of multipleParamKeys) {
      switch (key) {
        case 'dateRange':
          activeFilterCount = params.startTime ? ++activeFilterCount : activeFilterCount;
          activeFilterCount = params.endTime ? ++activeFilterCount : activeFilterCount;
          activeFilterCount = params.paymentDateStart ? ++activeFilterCount : activeFilterCount;
          activeFilterCount = params.paymentDateEnd ? ++activeFilterCount : activeFilterCount;
          activeFilterCount = params.eventDateStart ? ++activeFilterCount : activeFilterCount;
          activeFilterCount = params.eventDateEnd ? ++activeFilterCount : activeFilterCount;
          break;
      }
    }

    return activeFilterCount;
  }

  /**
   * Export data as CSV
   * @param fileName - Name of file to export
   * @param csvFile - CSV file to download
   */
  exportCSV(fileName: string, csvFile: string): void {
    const blob = new Blob([`\ufeff${csvFile}`], { type: 'text/csv' });
    const link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Export data as zip
   * @param fileName - Name of file to export
   * @param blob - Blob
   */
  exportZip(fileName: string, blob: Blob): void {
    const link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Open a snack bar that will automatically close
   * @param message - Message to show
   * @param duration - Amount of time to show snack bar (Default 3000ms)
   */
  openSnackBar(message: string, duration = 6000, multiLine = false): void {
    this.snackBar.open(message, null, { duration, panelClass: multiLine ? 'dtu-snackbar-multiline' : null });
  }

  /**
   * Set account
   * @param user - New user details
   */
  setAccount(user: UserSession): void {
    this.xsrfService.xsrfToken = user.csrfToken;
    this.account = { ...user, permissions: this.authApiService.getAccountPermissions(user) };

    this.metaService.updateTag({ name: 'dtu:type', content: user.type });
    this.metaService.updateTag({ name: 'dtu:email', content: user.email });
    this.metaService.updateTag({ name: 'dtu:company', content: user.company });
    this.metaService.updateTag({ name: 'dtu:title', content: user.title });
    this.metaService.updateTag({ name: 'dtu:vertical', content: user.sfdcVertical });
  }

  /**
   * Set theme
   * @param mode - New dark mode setting
   */
  setDarkMode(mode?: boolean): void {
    // Set default theme
    if (mode === undefined) {
      const isDarkModeEnabled = localStorage.getItem('dtu-dark-mode');
      mode = isDarkModeEnabled ? isDarkModeEnabled === 'true' : false;
    }

    // Add theme class to body
    const currentClass = this.darkMode ? 'dtu-dark-theme' : 'dtu-light-theme';
    const newClass = mode ? 'dtu-dark-theme' : 'dtu-light-theme';
    const renderer = this.rendererFactory.createRenderer(null, null);
    renderer.removeClass(document.body, currentClass);
    renderer.addClass(document.body, newClass);

    // Set dark mode
    this.darkMode = mode;
    localStorage.setItem('dtu-dark-mode', this.darkMode.toString());
  }

  /**
   * Set document metadata
   * @param description - New page description
   * @param keywords - New page keywords
   */
  setDocumentMeta(description: string, keywords: string): void {
    description = description ? description.replace(/(<([^>]+)>)/gi, '').replace(/\s\s+/g, ' ') : '';
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  /**
   * Set document title
   * @param newTitle - New page title
   */
  setDocumentTitle(newTitle?: string): void {
    if (newTitle) {
      this.titleService.setTitle(`${newTitle} | Dynatrace University`);
    } else {
      this.titleService.setTitle('Dynatrace University');
    }
  }

  /**
   * Set fullscreen state
   * @param state - New fullscreen state
   */
  setFullscreen(state: boolean): void {
    this.fullscreenChange.next(state);
  }

  /**
   * Check if admin privileges should be used
   */
  useAdminPrivileges(): boolean {
    return this.account.admin && !this.account.viewingAs;
  }
}
