import { Component, OnInit } from '@angular/core';
import { FlagNames } from '@dtu/types';
import Rox from 'rox-browser';

enum GlobalBannerProfile {
  Standard = 'standard',
  Warning = 'warning',
  Alert = 'alert',
}

interface GlobalBannerConfig {
  profile: GlobalBannerProfile;
  primaryText: string;
  secondaryText: string;
}

/**
 * Class representing the Global Banner component
 */
@Component({
  selector: 'dtu-global-banner',
  styleUrls: ['./global-banner.component.scss'],
  templateUrl: './global-banner.component.html',
})
export class GlobalBannerComponent implements OnInit {
  config: GlobalBannerConfig;
  isDismissed = false;

  GLOBAL_BANNER_PROFILE = GlobalBannerProfile;

  /**
   * Initialize component
   */
  ngOnInit(): void {
    try {
      this.config = JSON.parse(Rox.dynamicApi.value(FlagNames.GlobalBannerConfig, ''));
    } catch (error) {}
  }
}
