import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FlagNames } from '@dtu/types';
import { register } from '@dynatrace/common-components';
import Rox from 'rox-browser';
import { Subscription } from 'rxjs';
import { AppService } from './app.service';
import { FadeInAnimation } from './shared/animation/fade-in.animation';
import { AuthApiService } from './shared/api/auth.service';

// Initialize Window type definition
declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Class representing the App component
 */
@Component({
  animations: [FadeInAnimation],
  selector: 'dtu-app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  @ViewChild('overviewOutlet', { static: false }) overviewOutlet: RouterOutlet;

  isCommonNavHidden = false;
  isOverviewOutletActivated = false;

  // Initialize GTM datalayer
  dataLayer = window.dataLayer ? window.dataLayer : [];

  isFullscreen = false;
  fullscreenSubscription: Subscription;

  /**
   * App component
   * @param activatedRoute - Activated route details
   * @param appService - Global application service
   * @param authApiService - Auth API service
   * @param iconRegistry - Material icon registry
   * @param router - Angular router
   */
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly appService: AppService,
    private readonly authApiService: AuthApiService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly router: Router,
  ) {
    this.iconRegistry.setDefaultFontSetClass('material-icons-round');
  }

  /**
   * Initialize component
   */
  ngOnInit(): void {
    register();
    this.appService.initApp();
    this.isOverviewOutletActivated = Boolean(this.appService.account);

    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationEnd) {
        if (Rox.dynamicApi.isEnabled(FlagNames.EnableMaintenanceScreen, false)) {
          this.router.navigate(['/', 'maintenance']);
        }

        if (!this.activatedRoute.snapshot.fragment) {
          window.scrollTo(0, 0);
        }

        // Re-style marketing navigation
        setTimeout(() => {
          const overrideStylesId = 'dtu-nav-override-styles';
          const navigation = document.querySelector('#dt-nav-main');

          if (!this.isOverviewOutletActivated && navigation && !navigation.shadowRoot.querySelector(`#${overrideStylesId}`)) {
            const style = document.createElement('style');
            style.id = overrideStylesId;
            style.innerHTML = 'nav { top: unset !important; position: relative !important; z-index: 1099 } dismiss { height: 100vh; }';
            navigation.shadowRoot.appendChild(style);
          }
        }, 0);

        // Update page view in Google Tag Manager
        this.dataLayer.push({
          attributes: { route: event.urlAfterRedirects },
          event: 'DTURouteChange',
        });
        if (event.url.includes('perform-certifications') || event.url.includes('proctorapproval')) {
          this.isCommonNavHidden = true;
        }
      }
    });

    this.fullscreenSubscription = this.appService.fullscreenEvent.subscribe((fullscreen: boolean) => {
      this.isFullscreen = fullscreen;
    });
  }

  /**
   * Destroy component
   */
  ngOnDestroy(): void {
    this.fullscreenSubscription.unsubscribe();
  }
}
