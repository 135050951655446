import { Values } from './type-utils';

export const CertificationName = {
  DynatraceAssociateCertification: 'Dynatrace Associate Certification',
  DynatraceProfessionalCertification: 'Dynatrace Professional Certification',
  DynatraceMasterCertification: 'Dynatrace Master Certification',
  ServicesDeliveryObservability: 'Services Delivery Certification - Observability',
  ServicesDeliveryCloudOps: 'Services Delivery Certification - CloudOps',
  ServicesDeliverySaasUpgrade: 'Services Delivery Certification - SaaS Upgrade',
  AceServicesCertification: 'ACE Services Certification',
  DynatraceApplicationDevelopmentSpecialistCertification: 'Dynatrace Application Development Specialist Certification',
  DynatraceAdvancedSecuritySpecialistCertification: 'Dynatrace Advanced Security Specialist Certification',
  DynatraceDemAndBizAnalyticsSpecialistCertification: 'Dynatrace DEM and Business Analytics Specialist Certification',
  DynatraceAdvancedAutomationSpecialistCertification: 'Dynatrace Advanced Automation Specialist Certification',
  DynatraceAdvancedObservabilitySpecialistCertification: 'Dynatrace Advanced Observability Specialist Certification',
  DynatracePartnerSales: 'Dynatrace Partner Sales',
  DynatracePartnerSalesSpecialist: 'Dynatrace Partner Sales Specialist',
  DynatracePartnerSalesEngineer: 'Dynatrace Partner Sales Engineer',
  ProjectManagement: 'Partner Services Project Management Certification Exam',
  DynatraceAdministrationProfessionalCertification: 'Dynatrace Administration Professional Certification',
  DynatraceImplementationProfessionalCertification: 'Dynatrace Implementation Professional Certification',
} as const;
export type CertificationName = Values<typeof CertificationName>;

export const TwoPartCertificationId = {
  DynatraceAssociateCertification: 46943,
  DynatraceAdvancedSecuritySpecialistCertification: 46946,
  DynatraceDemAndBizAnalyticsSpecialistCertification: 46947,
  DynatraceAdvancedAutomationSpecialistCertification: 46945,
  DynatraceAdvancedObservabilitySpecialistCertification: 46944,
} as const;
export type TwoPartCertificationId = Values<typeof TwoPartCertificationId>;

export const Perform2025ExamName = {
  Perform2025DynatraceAssociateExam: 'Perform 2025 - Dynatrace Associate Certification Exam',
  Perform2025DynatraceAdvancedSecuritySpecialistCertificationExam:
    'Perform 2025 - Dynatrace Advanced Security Specialist Certification Exam',
  Perform2025DynatraceDemAndBizAnalyticsCertificationExam: 'Perform 2025 - Dynatrace DEM & Business Analytics Certification Exam',
  Perform2025DynatraceAdvancedAutomationSpecialistCertificationExam:
    'Perform 2025 - Dynatrace Advanced Automation Specialist Certification Exam',
  Perform2025DynatraceAdvancedObservabilitySpecialistCertificationExam:
    'Perform 2025 - Dynatrace Advanced Observability Specialist Certification Exam',
} as const;
export type Perform2025ExamName = Values<typeof Perform2025ExamName>;

export const CertificationExamName = {
  DynatraceAssociateExam: 'Dynatrace Associate Exam',
  DynatraceProfessionalWrittenExam: 'Dynatrace Professional Written Exam',
  DynatraceProfessionalPracticalExam: 'Dynatrace Professional Practical Exam',
  ServicesDeliveryObservabilityExam: 'Partner Services Delivery Certification Exam - Observability',
  ServicesDeliveryCloudOpsExam: 'Partner Services Delivery Certification Exam - CloudOps',
  ServicesDeliverySaasUpgradeExam: 'Services Delivery Certification Exam - SaaS Upgrade',
  DynatraceApplicationDevelopmentSpecialistExam: 'App Developer Certification Exam',
  DynatraceAdvancedSecuritySpecialistExam: 'Dynatrace Advanced Security Specialist Certification Exam',
  DynatraceDemAndBizAnalyticsSpecialistExam: 'Dynatrace DEM and Business Analytics Certification Exam',
  DynatraceAdvancedAutomationSpecialistExam: 'Dynatrace Advanced Automation Specialist Certification Exam',
  DynatraceAdvancedObservabilitySpecialistExam: 'Dynatrace Advanced Observability Specialist Certification Exam',
  DynatracePartnerSalesEngineerExam: 'Partner Sales Engineer Exam',
  ProjectManagementExam: 'Partner Services Project Management Certification Exam',

  ...Perform2025ExamName,
} as const;
export type CertificationExamName = Values<typeof CertificationExamName>;

export enum CertificationSchedulingMessages {
  NoExams = 'noExams',
  PrerequisitesIncomplete = 'prerequisitesIncomplete',
  RetakingTooSoon = 'retakingTooSoon',
  YearlyAttemptLimit = 'yearlyAttemptLimit',
  Eligible = 'eligible',
}

export interface CertificationEligibility {
  examId?: number;
  message: CertificationSchedulingMessages;
  availableDate?: string;
}

export const STANDARD_PREREQS = [
  CertificationName.DynatraceAssociateCertification,
  CertificationName.DynatraceProfessionalCertification,
  CertificationName.DynatraceAdvancedAutomationSpecialistCertification,
  CertificationName.DynatraceAdvancedObservabilitySpecialistCertification,
  CertificationName.DynatraceAdvancedObservabilitySpecialistCertification,
  CertificationName.DynatraceDemAndBizAnalyticsSpecialistCertification,
  CertificationName.DynatraceApplicationDevelopmentSpecialistCertification,
  CertificationName.DynatraceImplementationProfessionalCertification,
  CertificationName.DynatraceAdministrationProfessionalCertification,
];

/**
 * Only one certification in the array must be completed in order to unlock scheduling of requested exam.
 * If the value is null then there are no prerequisites for the certification and can be scheduled immediately, given all other
 * eligibility requirements are met.
 */
export const CERTIFICATION_PREREQS: Readonly<Record<CertificationName, readonly CertificationName[] | null>> = {
  [CertificationName.DynatraceAssociateCertification]: null,
  [CertificationName.DynatraceProfessionalCertification]: [CertificationName.DynatraceAssociateCertification],
  [CertificationName.DynatraceMasterCertification]: [CertificationName.DynatraceProfessionalCertification],
  [CertificationName.ServicesDeliveryObservability]: [
    CertificationName.DynatraceProfessionalCertification,
    CertificationName.DynatraceMasterCertification,
  ],
  [CertificationName.ServicesDeliveryCloudOps]: [CertificationName.ServicesDeliveryObservability],
  [CertificationName.ServicesDeliverySaasUpgrade]: [CertificationName.DynatraceProfessionalCertification],
  [CertificationName.AceServicesCertification]: STANDARD_PREREQS,
  [CertificationName.DynatraceAdvancedSecuritySpecialistCertification]: STANDARD_PREREQS,
  [CertificationName.DynatraceDemAndBizAnalyticsSpecialistCertification]: STANDARD_PREREQS,
  [CertificationName.DynatraceAdvancedAutomationSpecialistCertification]: STANDARD_PREREQS,
  [CertificationName.DynatraceAdvancedObservabilitySpecialistCertification]: STANDARD_PREREQS,
  [CertificationName.DynatraceApplicationDevelopmentSpecialistCertification]: STANDARD_PREREQS,
  [CertificationName.DynatracePartnerSales]: null,
  [CertificationName.DynatracePartnerSalesSpecialist]: null,
  [CertificationName.DynatracePartnerSalesEngineer]: null,
  [CertificationName.ProjectManagement]: null,
  [CertificationName.DynatraceAdministrationProfessionalCertification]: STANDARD_PREREQS,
  [CertificationName.DynatraceImplementationProfessionalCertification]: STANDARD_PREREQS,
} as const;
