/**
 * Maintenance Guard Service
 *
 * This service redirects a user to their dashboard if the Maintenance screen is not active
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FlagNames } from '@dtu/types';
import Rox from 'rox-browser';

/**
 * Class representing the Maintenance Guard service
 */
@Injectable()
export class MaintenanceGuard {
  /**
   * Maintenance Guard service
   * @param router - Angular router
   */
  constructor(private readonly router: Router) {}

  /**
   * Check if Maintenance screen is active
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    try {
      if (!Rox.dynamicApi.isEnabled(FlagNames.EnableMaintenanceScreen, false)) {
        this.router.navigate(['account', 'dashboard']);
        return false;
      }
      return true;
    } catch (error) {
      return true;
    }
  }

  /**
   * For child routes, Maintenance screen is active before routing
   * @param route - Activate route snapshot
   * @param state - Router state snapshot
   * @returns If route is allowed
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
